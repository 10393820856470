import React from 'react'
import styled from 'styled-components'

import present from '../assets/present.png'
import Container from './Container'

const StyledFooter = styled.footer`
  text-align: center;
  position: relative;
  overflow-x: hidden;

  img {
    height: 37px;
    margin: 3rem 0;
  }

  h1 {
    position: absolute;
    top: 63px;
    font-weight: bolder;
    font-size: 5rem;
    left: -7rem;
    color: #000;
    opacity: 0.09;
  }
`

const StyledCopyright = styled.div`
  display: flex;
  align-items: center;
  height: 79px;
  background: #000;
  color: #fff;
  margin-top: 4rem;
`

const Footer = () => {
  return (
    <StyledFooter>
      <img src={present} alt='presented by clover' />
      <h1>awardsawardsawardsawardsawards</h1>
      <StyledCopyright>
        <Container>
          <p>
            Copyright © 2020 Official Black Wall Street | All Rights Reserved
            Terms & Conditions
          </p>
        </Container>
      </StyledCopyright>
    </StyledFooter>
  )
}

export default Footer
