import React from 'react'
import styled from 'styled-components'

const StyledCard = styled.div`
  text-align: center;
  min-height: 120px;
  max-height: fit-content;
  background: #000;
  box-shadow: 7px 7px 10px #00000029;
  border-radius: 14px;
  position: relative;
  bottom: -40px;
  flex-basis: 23%;
  width: 100px;
  padding-bottom: 1rem;

  @media screen and (max-width: 480px) {
    flex-basis: 80%;
    margin-bottom: 4rem;
  }
`

const StyledCardDetails = styled.div`
  position: relative;
  top: -30px;
  width: 80%;
  margin: 0 auto;
  color: #fff;

  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    border-radius: 100%;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 25;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 12px;
    font-weight: 200;
  }

  a {
    color: white;
  }
`

const Card = ({ finalist }) => {
  return (
    <StyledCard>
      <StyledCardDetails>
        <img src={finalist.avatar} alt='' />
        <h3>{finalist.name}</h3>
        <p>
          <a href={finalist.link} target='__blank'>
            {finalist.company}{' '}
          </a>
        </p>
      </StyledCardDetails>
    </StyledCard>
  )
}

export default Card
