import { Fragment } from 'react'
import ReactGA from 'react-ga'

import './App.css'

// Assets
import andreaButter from './assets/Andrea Butler .png'
import dawnKelly from './assets/Dawn Kelly .png'
import dianneAustin from './assets/Dianne Austin and Pamela Shaddock.png'
import donnel from './assets/Donnel.png'
import drChristine from './assets/Dr. Christine.png'
import ivyLawrence from './assets/Ivy Lawrence-.png'
import keewaNurullah from './assets/Keewa Nurullah.png'
import kewanta from './assets/Kewanta.png'
import kwesiElder from './assets/Kwesi and Kennise Elder.png'
import lesleyBryant from './assets/Lesley Bryant.png'
import makeda from './assets/Makeda.png'
import zacharaySmith from './assets/Zacharey Smith.png'
import terriNichelle from './assets/Terri-Nichelle Bradley.png'
import shirleyDorosainvil from './assets/Shirley Dorsainvil.png'
import omar from './assets/Omar and .png'

// Custom Imports
import Footer from './components/Footer'
import Header from './components/Header'
import Navbar from './components/Navbar'
import WinnerCategory from './components/WinnerCategory'

ReactGA.initialize('UA-63840607-7')
ReactGA.pageview(window.location.pathname + window.location.search)

const data = [
  {
    awardTitle: 'Winner - OBWS Black Entrepreneur of the Year',
    winner: {
      name: 'Keewa Nurullah',
      company: 'Kido',
      link: 'https://kidochicago.com/',
      info: "Kido is an award-winning children's shop focused on representation, inclusivity, and building community on the Southside of Chicago.",
      avatar: keewaNurullah
    },
    finalists: [
      {
        name: 'Dr. Christine Izuakor',
        company: 'Cyber Pop-up',
        link: 'https://www.cyberpopup.com/',
        avatar: drChristine
      },
      {
        name: 'Lesley Bryant',
        company: 'Lady Clipper Barbershop',
        link: 'https://www.theladyclipper.com/',
        avatar: lesleyBryant
      },
      {
        name: 'Terri-Nichelle Bradley',
        company: 'Brown Toy Box',
        link: 'https://browntoybox.com/',
        avatar: terriNichelle
      },
      {
        name: 'Zacharey Smith',
        company: 'Best Mobile Auto & Diesel Repair, Inc.',
        link: 'https://www.bestmbl.com/',
        avatar: zacharaySmith
      }
    ]
  },
  {
    awardTitle: 'Winner - OBWS Black Innovator of the Year',
    winner: {
      name: 'Makeda Ricketts',
      company: 'PinkThink',
      link: 'https://www.thepinkthink.org/',
      info: 'PinkThink makes interest-oriented and engaging STEM education products for girls. In order to teach girls coding, PinkThink has created Codeable Style (cStyle) Bracelets, the first programmable wearable.',
      avatar: makeda
    },
    finalists: [
      {
        name: 'Andrea Butler',
        company: 'Sesi Magazine',
        link: 'https://sesimag.com/',
        avatar: andreaButter
      },
      {
        name: 'Kewanta Brooks',
        company: 'Elite Kids TV',
        avatar: kewanta,
        link: 'https://elitekidstv.com/'
      },
      {
        name: 'Omar and Kimberly Finley',
        company: 'The Listening Tree',
        avatar: omar,
        link: 'https://listeningtreebooks.com/'
      },
      {
        name: 'Dianne Austin and Pamela Shaddock',
        company: 'Coils To Locs',
        avatar: dianneAustin,
        link: 'https://coilstolocs.com/'
      }
    ]
  },
  {
    awardTitle: 'Winner - OBWS Black Social Entrepreneur of the Year',
    winner: {
      name: 'Ivy Lawrence-Walls',
      company: 'Ivy Leaf Farms',
      link: 'https://www.ivyleaffarms.com/',
      info: 'Ivy Leaf Farms is a community based farm dedicated to food equity and complete neighborhood wellness in Houston, Texas.',
      avatar: ivyLawrence
    },
    finalists: [
      {
        name: 'Dawn Kelly',
        company: 'The Nourish Spot',
        avatar: dawnKelly,
        link: 'https://the-nourish-spot.myshopify.com/'
      },
      {
        name: 'Donnel Baird',
        company: 'BlocPower',
        avatar: donnel,
        link: 'https://www.blocpower.io/'
      },
      {
        name: 'Kwesi and Kennise Elder',
        company: 'Georgia Roots Urban Farm',
        avatar: kwesiElder,
        link: 'https://www.garoots.farm/'
      },
      {
        name: 'Shirley Dorsainvil',
        company: 'Haitians Who Blog, LLC',
        avatar: shirleyDorosainvil,
        link: 'https://www.haitianswhoblog.com/'
      }
    ]
  }
]

function App() {
  return (
    <Fragment>
      <Navbar />
      <Header />

      <WinnerCategory category={data[0]} reverse />
      <WinnerCategory category={data[1]} color='#FFE702' />
      <WinnerCategory category={data[2]} color='#78CCF6' reverse />
      <Footer />
    </Fragment>
  )
}

export default App
