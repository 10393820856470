import React from 'react'
import styled from 'styled-components'

import Container from './Container'
import Card from './Card'

const StyledWinnerCategory = styled.section`
  padding: 3rem 0;
`

const StyledWinner = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 2rem;

  div {
    flex-basis: 40%;

    h4 {
      font-size: 1rem;
      color: #fff;
      background-color: #000;
      font-weight: normal;
      width: fit-content;
      padding: 3px 5px;
    }

    h1 {
      font-size: 3rem;
      padding: 0;
      margin: 0;
      font-weight: 600;
    }

    h3 {
      padding: 0;
      margin: 0;
      font-weight: 200;
      font-size: 1.3rem;
      line-height: 1px;
      margin-bottom: 3rem;
    }
  }

  img {
    width: 400px;
    border-radius: 100%;
  }

  @media screen and (max-width: 480px) {
    div {
      flex-basis: 100%;

      h4 {
        font-size: 0.8rem;
      }
    }

    img {
      width: 300px;
    }
  }
`

const StyledFinalists = styled.div`
  background-color: ${props => (props.color ? props.color : '#60d404')};
  margin-bottom: 3rem;

  h2 {
    font-size: 30px;
    margin-bottom: 3rem;
    position: relative;
    top: -22px;
    left: 50px;
  }

  @media screen and (max-width: 480px) {
    h2 {
      text-align: center;
      left: 0;
    }
  }
`

const StyledFinalistsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`

const WinnerCategory = ({ color, reverse, category }) => {
  const { awardTitle, winner, finalists } = category
  return (
    <StyledWinnerCategory>
      <Container>
        <StyledWinner reverse={reverse}>
          <img src={winner.avatar} alt='avatar' />
          <div>
            <h4>{awardTitle}</h4>
            <h1>{winner.name}</h1>
            <h3>
              <a href={winner.link} target='__blank'>
                {winner.company}{' '}
              </a>
            </h3>
            <p>{winner.info}</p>
          </div>
        </StyledWinner>
      </Container>
      <StyledFinalists color={color}>
        <Container>
          <h2>Finalists</h2>
          <StyledFinalistsInner>
            {finalists.map(finalist => {
              return <Card key={finalist.name} finalist={finalist} />
            })}
          </StyledFinalistsInner>
        </Container>
      </StyledFinalists>
    </StyledWinnerCategory>
  )
}

export default WinnerCategory
