import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../assets/obws_logo.png'

// Custom Imports
import Container from './Container'

// Assets
// import logo from '../assets/'

const StyledTopNav = styled.div`
  display: flex;
  align-items: center;
  height: 58px;
  background: #000000;

  @media screen and (max-width: 480px) {
    height: fit-content;
    /* position: fixed; */
    /* width: 100%; */
    display: ${props => (props.showMobile ? 'block' : 'none')};

    img {
      display: none;
    }
  }
`
const StyledTopNavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin: 0 1rem;
  }

  img {
    height: 40px;
  }

  @media screen and (max-width: 480px) {
    a {
      display: block;
      margin: 1rem 0;
    }
  }
`

const StyledBottomNav = styled.div`
  display: flex;
  align-items: center;
  height: 53px;
  box-shadow: 0px 3px 30px #0000004f;

  @media screen and (max-width: 480px) {
    height: fit-content;
    display: 'block';
    padding-bottom: 1rem;
    background-color: #000;
    display: ${props => (props.showMobile ? 'block' : 'none')};
  }
`

const StyledBottomNavInner = styled.div`
  display: flex;
  justify-content: center;

  a {
    display: inline-block;
    margin: 0 1rem;
    color: #000;
    text-decoration: none;
  }

  @media screen and (max-width: 480px) {
    display: block;

    a {
      display: block;
      margin: 0 0 1rem 0;
      color: #fff;
    }
  }
`

const StyledMobileNav = styled.div`
  height: 80px;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  /* position: fixed; */
  width: 100%;

  img {
    height: 50px;
  }

  a {
    color: #fff;
  }

  @media screen and (min-width: 481px) {
    display: none;
  }
`

const StyledMobileNavInner = styled.div`
  height: 100;
  display: flex;
  justify-content: space-between;
`

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false)

  const openNavHandler = () => {
    setOpenNav(prevState => {
      return !prevState
    })
  }

  return (
    <Fragment>
      {/* Mobile Nav */}
      <StyledMobileNav>
        <Container>
          <StyledMobileNavInner>
            <img src={logo} alt='Logo' />
            <a href='#!' onClick={openNavHandler}>
              <FontAwesomeIcon size={'3x'} icon={faBars} />
            </a>
          </StyledMobileNavInner>
        </Container>
      </StyledMobileNav>

      {/* Top Nav */}
      <StyledTopNav showMobile={openNav}>
        <Container>
          <StyledTopNavInner>
            <img src={logo} alt='Logo' />
            <div>
              <a href='https://obws.com/login/' target='__blank'>
                Log In
              </a>
              <a href='https://obws.com/signup/' target='__blank'>
                Sign Up
              </a>
            </div>
          </StyledTopNavInner>
        </Container>
      </StyledTopNav>

      {/* Bottom Nav */}
      <StyledBottomNav showMobile={openNav}>
        <Container>
          <StyledBottomNavInner>
            <a href='https://obws.com/about-us/' target='__blank'>
              About Us
            </a>
            <a href='https://shop.obws.com/' target='__blank'>
              Shop
            </a>
            <a href='#!' target='__blank'>
              Features
            </a>
            <a href='https://obws.com/advertise/' target='__blank'>
              Advertise
            </a>
            <a href='https://obws.com/search/business/' target='__blank'>
              Businesses
            </a>
            <a href='#!' target='__blank'>
              Events
            </a>
            <a
              href='https://obws.com/join/'
              target='__blank'
              style={{ color: '#F15A24' }}
            >
              List Your Business
            </a>
          </StyledBottomNavInner>
        </Container>
      </StyledBottomNav>
    </Fragment>
  )
}

export default Navbar
