import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import logo2 from '../assets/logo2.png'
import background from '../assets/header-background.png'

const StyledHeader = styled.div`
  box-shadow: 6px 6px 10px #00000029;
  padding: 5rem 0 0;
`

const StyledHeaderLogo = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 3rem;

  img {
    width: 60%;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
`

const StyledHeaderTitle = styled.div`
  text-align: center;
  color: #fff;
  background: url(${background}) 0% 0% no-repeat;
  background-size: cover;
  padding: 2.5rem 0;
  margin-bottom: 0;

  h1 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 49px;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 27px;
  }
`

const Header = ({ title, subTitle }) => {
  return (
    <StyledHeader>
      <Container>
        <StyledHeaderLogo>
          <img src={logo2} alt='Logo2' />
        </StyledHeaderLogo>
      </Container>
      <StyledHeaderTitle>
        <Container>
          <h1>2021 Award Winners</h1>
          <p>Congratulations to the winners!</p>
        </Container>
      </StyledHeaderTitle>
    </StyledHeader>
  )
}

export default Header
